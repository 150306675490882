import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { graphql, Link } from "gatsby"
import axios from "axios"
import Cookies from "universal-cookie"
import { isLoggedIn, getUser } from "../../../services/auth"
import config from "../../../config/config"
import SlideToggle from "react-slide-toggle"
import Layout from "../../../templates/Page"
import Header from "../../ContentBuilder/Header"
import SwiperList from "../../ContentBuilder/SwiperList"
import { Tabs, Tab, Row, Col, Nav } from "react-bootstrap"
import SelectionRelatedItems from "./SelectionRelatedItems"
import ShowMoreText from "react-show-more-text"
import CreateBoardModal from "../../ContentBuilder/Modal/CreateBoardModal"
import { getRecentPages, setRecentPages } from "../../../services/account-services"
import RecentViews from "../../RecentViews"
import Selections from "../../Selections"

import QuickViewModalData from "../../ContentBuilder/Modal/QuickViewModalData"
import QuickViewModalHomeDesignData from "../../ContentBuilder/Modal/QuickViewModalHomeDesignData"

const StyleDetail = ({ data }) => {
  var [title, setTitle] = useState("")
  const [isReadmore, setReadmore] = useState(true)
  const [isViewmore, setViewmore] = useState(true)
  const [item, setItem] = useState({})
  const [globalConfig, setGlobalConfig] = useState({})
  const search = typeof window !== "undefined" ? window.location.search : "/"
  const params = new URLSearchParams(search)
  var vid = params.get("vid")
  
  useEffect(() => {
    if (data) {
      setItem(data.allSilverStripeDataObject.nodes[0].UndigitalSelection)
      setGlobalConfig(data.silverStripeDataObject.UndigitalGlobalConfig);
    }
  }, [JSON.stringify(data)]);
  const handleChangeTitle = e => {}
  var designers = ""
  var designersArr = []
  var designersList = item.Designers
  if (designersList !== null && typeof designersList !== 'undefined') {
    designersList.forEach(function (item, index) {
      designersArr.push(item.UndigitalDesigner.name)
    })
    designers = designersArr.join(", ")
  }
  
  var disclaimers = []
  if (typeof item.Disclaimer !== 'undefined' && item.Disclaimer) {
    disclaimers = item.Disclaimer
  }
  
  var rrp = item.costPrice
  rrp = parseFloat(Math.round(rrp * 1000) / 1000).toFixed(2)
  item.formatted_rrp =
    "$" + rrp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    
    const [showQuickView, setShowQuickView] = useState(false)
  const [showQuickViewHomeDesign, setShowQuickViewHomeDesign] = useState(false)
  const [quickViewData, setQuickViewData] = useState(null)
    const setUpQuickViewModal = useCallback((e, item) => {
    e.preventDefault()
    setQuickViewData(item)
    if(item.ProductType === 'HomeDesign'){
        setShowQuickViewHomeDesign(true)
    }else{
        setShowQuickView(true)
    }
  }, [])
  
  
  var boardsObjs = null
  if (typeof item.boardsData !== "undefined") {
    var boardsData = item.boardsData
    var objects = JSON.parse(boardsData)
    boardsObjs = Object.keys(objects).map(function(key){
        let item = objects[key]  
        return item
    })
    boardsObjs = boardsObjs.filter(function (item, index) {
        return true
    })
    //if (boardsObjs.length > 0 && title === "") title = boardsObjs[0].Name
    if (boardsObjs.length > 0){
        boardsObjs.forEach(function (item, index) {        
          if (item.Images) {
            let images = Object.keys(item.Images).map(key => item.Images[key])
            images = images.filter(function (item, index) {
                let valid = true
                switch(item.ProductType){
                    case 'HomeDesign':
                        valid = globalConfig.hideHomeDesignSection !== 1
                        break
                    case 'Facade':
                        valid = globalConfig.hideFacadesSection !== 1
                        break
                    case 'Furniture':
                        valid = globalConfig.hideFurnitureSection !== 1
                        break
                    case 'Cabinetry':
                        valid = globalConfig.hideCabinetrySection !== 1
                        break
                    case 'FinishesFixtures':
                        valid = globalConfig.hideFinishesFixturesSection !== 1
                        break
                    default: break
                }
                return valid
            })
            item.Images = images        
          }
        })
    }
  }
  item.boards = boardsObjs
  const showAddBoard = () => {
    if (isLoggedIn()) {
      setShowCreateBoard(true)
    } else {
      if (typeof window !== "undefined") {
        window.location.href = "/login/"
      }
    }
  }

  const Member = getUser();
  let boards = [];
  
  if(isLoggedIn()){
    boards = Object.keys(Member.Boards).map(key => Member.Boards[key]);    
  }

  let isSaved = false
  for (const board of boards) {
    const items = JSON.parse(board.Items)
    for (const key in items) {
      if (key.includes('Selection') && items[key].ID == item.objectID) {
        isSaved = true
      }
    }
  }
  if (item && item.status === 'Active') {
    const recentPages = getRecentPages();
    setRecentPages([
      { ...item, className: 'Selection' },
      ...recentPages.filter(rp => !(rp.className == 'Selection' && rp.objectID == item.objectID))
    ])
  }

    const [showCreateBoard, setShowCreateBoard] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const imgElement = useRef(null)
  return (
    <Layout hideHeader>
      <CreateBoardModal
        show={showCreateBoard}
        handleClose={() => setShowCreateBoard(false)}
        objectClass="Selection"
        objectID={item.objectID}
      />
      <Header isLogin />

      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/explore/style/">Style</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {item.name}
            </li>
          </ol>
        </nav>

        <div className="main-title-wrap">
          <div className="board-page-title flex-1">
            <div className="row align-items-center">
              <div className="col-md">
                <h1 className="product-name-title text-uppercase h2">
                  {item.name} by {designers}
                </h1>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="button-link text-decoration-none ml-1"
          >
              {isSaved ? (
                <div className="d-flex flex-column align-items-center btn-add-to-board"
                  onClick={() => showAddBoard()}
                  >
                  <svg
                    className="mb-1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                  >
                    <path
                      id="Path_878"
                      data-name="Path 878"
                      d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z"
                      transform="translate(21.982 6.975)"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      strokeWidth="1.5"
                    />
                  </svg>
                  LOVED
                </div>
              ) : (
                <div
                  className="d-flex flex-column align-items-center text-black btn-add-to-board"
                  onClick={() => showAddBoard()}
                >
                <img src="/img/heart-grey.svg" alt=""/>
                ADD
                </div>
              )}
          </button>
        </div>
        <div className="wrap-section row">
          <div className="col-md-8">
            <h3 className="info-title">Mood</h3>
            <div className={`landing-detail-image-wrap mt-3 mb-4`}>
                <a href={item.imagePath} className="d-block main-img pointer" data-fancybox="gallery">
                  <img
                    src={item.imagePath}
                    alt=""
                    ref={imgElement}
                    onLoad={() => setLoaded(true)}
                  />
                </a>
            </div>
          </div>
          <div className="col-md-4 information">
            <h3 className="mb-2 info-title">Information</h3>

            <div className="list-group list-group-flush mb-4">
            {/*
            {globalConfig.hidePricingGlobally == 0 && (
              <div className="list-group-item px-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0">Price from</h5>
                  </div>
                  <div className="col-auto">
                    <p className="h4">{item.formatted_rrp}</p>
                  </div>
                </div>
              </div>
              )}
             */}
                {/*
              <div className="list-group-item px-0">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Designer</h5>
                  </div>
                  <div className="col-auto">
                    <p>{designers}</p>
                  </div>
                </div>
              </div>
                 */}
              <div className="list-group list-group-flush mb-4">
                {item.about && (
                  <div className="list-group-item px-0 about-wrap">
                    <ShowMoreText
                      more={<small className="more">Read more</small>}
                      less={<small className="less">Read less</small>}
                      className="content-wrap"
                      anchorClass="button-link d-block mt-1 text-right"
                      expanded={false}
                      width={0}
                    >
                      <div
                          dangerouslySetInnerHTML={{ __html: item.about }}
                        />
                    </ShowMoreText>
                  </div>
                )}
              </div>
              {disclaimers.length > 0 && (
                  <SlideToggle collapsed="false">
                    {({ toggle, setCollapsibleElement, toggleState }) => (
                      <div className={`list-group-item px-0${
                        toggleState == "EXPANDED" ? " expanded" : ""
                      }`}>
                        <h5
                          className={`mb-0 info-toggle${
                            toggleState == "EXPANDED" ? " active" : ""
                          }`}
                          onClick={e => {
                            toggle(e)
                            e.preventDefault()
                          }}
                        >
                          Disclaimer
                        </h5>
                        <div className="content-wrap" ref={setCollapsibleElement}>  
                            {disclaimers.map((disclaimer, idx) => (                        
                                <div className="mb-2" key={`disclaimer-${idx}`} dangerouslySetInnerHTML={{ __html: disclaimer.UndigitalTextAsset.content }}/>
                            ))}     
                        </div>   
                      </div>
                    )}
                  </SlideToggle>
                )}
            </div>
            {/*
            {item.Features && (
              <div className="d-flex flex-direction-column align-items-left mb-15">
                <h3 className="mb-2 info-title">
                  Feature
                </h3>
                <div className="mt-15">
                  <ul className="custom-list">
                    {item.Features.map((feature, idx) => (
                      <li key={`feature${idx}`}>
                        {feature.UndigitalStyleFeature.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
             */}
            <div className="row justify-content-center mt-30">
              <div className="col-xl-8 col-md-12 col-sm-6 col-8">
                <a
                  href="/contact-us/"
                  className="btn btn-black btn-block btn-lg px-2 my-1"
                >
                  Enquire now
                </a>
              </div>
            </div>
          </div>
        </div>
        {item.boards && (
        <div className="pb-4">
            <div className="board-page-title small">
                <h3 className="product-name-title d-flex align-items-center">
                    Selections
                </h3>
            </div>
            {item.boards.map((board, idx) => (
                <div key={`boardcontent${idx}`}>                    
                    {(board.Images && board.Images.length > 0) && (
                    <>
                    <div className="title-swiper mb-2">
                        <p className={`slide-title`}>{board.Name}</p>
                    </div>
                    <div className="row recent-view mb-3">
                        {board.Images.map((image, index) => (
                            <div key={`boardchild${idx}item${index}`} className="col-6 col-md-6 col-lg-4 col-xl-3">
                              <div className={`grid-item${image.ProductData.TileBlackTitle == 1 ? ' text-black' : ''}`}>
                                <a
                                onClick={e => setUpQuickViewModal(e, image)}
                                href={image.ProductData.Link} className={`grid-item-inner`}>
                                  <img src={image.ImagePath} alt=""/>
                                  <div className="lightbg grid-caption show-item-name">
                                    {(image.ProductData.vt && image.ProductData.vt.Name) ?(
                                        <h4>{image.ProductData.Name}</h4>
                                    ):(
                                        <h4>{image.ProductData.vt.Name}</h4>
                                    )}                                    
                                   {(image.ProductData.Category) && (
                                    <div className="grid-bottom">
                                      <p className="variant-name">{image.ProductData.Category}</p>
                                    </div>
                                    )}
                                  </div>
                                </a>
                              </div>
                            </div>
                        ))}
                    </div>     
                    </>
                    )}
                </div>
            ))}
        </div>
        )}
        <div className="pb-4">
          <div className="board-page-title small">
            <h3 className="product-name-title d-flex align-items-center">
              Related Products
            </h3>
          </div>
          <SelectionRelatedItems id={item.objectID} />
        </div>
        <RecentViews/>
      </div>
      {quickViewData && (
        <QuickViewModalData
          show={showQuickView}
          handleClose={() => setShowQuickView(false)}
          item={quickViewData}
          hidePricingGlobally={globalConfig.hidePricingGlobally}
        />
      )}
      {quickViewData && (
      <QuickViewModalHomeDesignData
          show={showQuickViewHomeDesign}
          handleClose={() => setShowQuickViewHomeDesign(false)}
          item={quickViewData}
          hidePricingGlobally={globalConfig.hidePricingGlobally}
        />
         )}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
            hidePricingGlobally
            hideStyleSection
            hideHomeDesignSection
            hideFacadesSection    
            hideFinishesFixturesSection
            hideCabinetrySection
            hideFurnitureSection
        }
      }
    allSilverStripeDataObject(
      filter: {
        UndigitalSelection: {
          urlSegment: { eq: $slug }
          status: { in: ["Active"] }
        }
      }
    ) {
      nodes {
        UndigitalSelection {
          status
          name
          boardsData
          costPrice
          urlSegment
          objectID
          about
          Designers {
            UndigitalDesigner {
              name
            }
          }
          Disclaimer {
            UndigitalTextAsset {
              title
              content
            }
          }
          imagePath
          Features {
            UndigitalStyleFeature {
              name
              objectID
            }
          }
          Style {
            UndigitalProductStyle {
              name
              objectID
            }
          }
        }
      }
    }
  }
`
export default StyleDetail
